@font-face {
  font-family: "iransansxv";
  src: url("../fonts/IRANSansXV.woff") format("woff"),
    /* will be the standard and works in Safari now */
      url("../fonts/IRANSansXV.woff2") format("woff2");
  /* for the other supporting browsers */
  font-weight: 100 900;
}
/* iransans version 7.5 */

* {
  font-family: "iransansxv";
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}
